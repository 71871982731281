import ReactDOM from 'react-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useRequestData, useLabels } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import MyCarsModal from '../components/MyCarsModal';
import { MY_CARS_KEY, EVENT_TYPE, TOPIC_KEY } from '../global-constants';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { getTrackingObjContainer } from '../utils/getTrackingObjContainer';
import {
	resetState,
	updateAlerts,
	unsubscribeAll,
	unsubscribeSingle,
	isStateEqual,
	updateLocalStorage
} from '../utils';
import '../global-sass/main.scss';
import { useFetchData, useFetchEAS } from '../hooks';

function Widget() {
	const currentEventType = useRef();
	const {
		moreRequestData: {
			accountId,
			parameterMap: { eventType = '', userId, priceAlertId, vehicleId }
		},
		myCarsData
	} = useSelector((state) => state);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [myCarsMobileIconRoot, setMyCarsMobileIconRoot] = useState(null);
	const { deviceType, windowId, widgetName } = useRequestData();
	const labels = useLabels();
	const RECENTLY_VIEWED = labels.get('RECENTLY_VIEWED');
	const TRACKING_PRICE = labels.get('TRACKING_PRICE');
	const SAVED = labels.get('SAVED');
	const SHOP_OUR_INVENTORY = labels.get('SHOP_OUR_INVENTORY');
	const trackingObjLabels = {
		RECENTLY_VIEWED,
		TRACKING_PRICE,
		SAVED,
		SHOP_OUR_INVENTORY
	};
	const trackingObjContainer = getTrackingObjContainer(trackingObjLabels);
	const isDesktop = deviceType === 'DESKTOP';

	const isEnableMyCars =
		isBrowser &&
		window?.DDC?.siteProperties?.enableMyCars === 'true' &&
		!(window?.DDC?.siteProperties?.disableMyCarsToolbar === 'true');

	/* Insert new key into unset object here, this will also act as the template to backfill missing keys */
	useLocalStorage(
		MY_CARS_KEY,
		{
			email: '',
			accountId,
			newTrackedPriceCount: 0,
			newSaveCount: 0,
			vehicles: []
		},
		isEnableMyCars
	);

	const { response: alerts } = useFetchData({
		userId,
		eventType,
		isEnableMyCars
	});

	useFetchEAS({
		eventType,
		userId,
		priceAlertId,
		isEnableMyCars
	});

	useEffect(() => {
		if (isBrowser && !isDesktop) {
			const portalDiv = document.getElementById(
				'mycars-mobile-icon-root'
			);
			setMyCarsMobileIconRoot(portalDiv);
		}
	}, [isDesktop]);

	useEffect(() => {
		if (
			alerts &&
			alerts.length > 0 &&
			myCarsData?.vehicles &&
			userId &&
			isEnableMyCars
		) {
			let newState = updateAlerts(myCarsData, alerts);
			if (
				(eventType === EVENT_TYPE.VALIDATE || !eventType) &&
				!isStateEqual(myCarsData, newState)
			) {
				updateLocalStorage(newState, MY_CARS_KEY, TOPIC_KEY);
			}
			if (eventType === EVENT_TYPE.UNSUBSCRIBE_SINGLE) {
				newState = unsubscribeSingle(newState, vehicleId);
				updateLocalStorage(newState, MY_CARS_KEY, TOPIC_KEY);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alerts]);

	useEffect(() => {
		if (
			myCarsData &&
			myCarsData?.vehicles &&
			eventType &&
			eventType === EVENT_TYPE.UNSUBSCRIBE_ALL &&
			currentEventType.current !== eventType &&
			isEnableMyCars
		) {
			currentEventType.current = eventType;
			const newState = unsubscribeAll(myCarsData);
			updateLocalStorage(newState, MY_CARS_KEY, TOPIC_KEY);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myCarsData]);

	const onModalClose = () => {
		setIsModalVisible(false);
		const newState = resetState(myCarsData);
		if (!isStateEqual(myCarsData, newState)) {
			updateLocalStorage(newState, MY_CARS_KEY, TOPIC_KEY);
		}
	};

	const showBadge =
		myCarsData &&
		(myCarsData.newSaveCount > 0 || myCarsData.newTrackedPriceCount > 0);

	const iconClick = () => {
		const trackingObj = trackingObjContainer['MyCars nav'];
		trackEvent(widgetName, windowId, trackingObj);
		setIsModalVisible(true);
	};

	const wrapperClasses = [
		'ws-mycars-nav-wrapper',
		isDesktop ? 'd-flex align-items-center justify-content-center' : ''
	];
	const buttonWrapperClasses = [
		isDesktop ? 'd-flex mycars-integration-navbar' : ''
	];
	const buttonClasses = [
		'btn-link btn-no-decoration border-0 text-decoration-none position-relative',
		isDesktop ? 'pl-4' : '',
		isModalVisible ? 'active' : ''
	];

	const widgetContent = (
		<>
			<div className={setClassNames(wrapperClasses)}>
				{isDesktop && <div data-location="header-toolbar" />}

				{isEnableMyCars && (
					<div className={setClassNames(buttonWrapperClasses)}>
						<button
							type="button"
							className={setClassNames(buttonClasses)}
							aria-label={labels.get('OPEN_MY_CARS')}
							aria-pressed={isModalVisible}
							onClick={iconClick}
						>
							<i className="ddc-icon ddc-nav-icon ddc-icon-mycars-minimal-login ddc-icon-size-xlarge" />
							{showBadge && (
								<span
									className={
										isDesktop
											? 'mycars-nav-badge--desktop'
											: 'mycars-nav-badge--mobile'
									}
									data-testid="mycars-nav-badge"
								/>
							)}
						</button>
					</div>
				)}
			</div>
			{myCarsData && myCarsData?.vehicles && isEnableMyCars && (
				<MyCarsModal
					isOpen={isModalVisible}
					onModalClose={onModalClose}
				/>
			)}
		</>
	);

	if (!isEnableMyCars && !isDesktop) {
		return null;
	}

	if (isDesktop) {
		return widgetContent;
	}

	if (myCarsMobileIconRoot) {
		return ReactDOM.createPortal(widgetContent, myCarsMobileIconRoot);
	}

	return null;
}

export default Widget;
