export const getTrackingObjContainer = (labels) => {
	const { RECENTLY_VIEWED, TRACKING_PRICE, SAVED, SHOP_OUR_INVENTORY } =
		labels;

	const trackingObjContainer = {
		'MyCars nav': {
			element: 'MyCars nav',
			result: 'MyCars menu opened'
		},
		[RECENTLY_VIEWED]: {
			element: 'Recently viewed link',
			result: 'User navigates to recently viewed inventory'
		},
		[TRACKING_PRICE]: {
			element: 'Tracking price link',
			result: 'User navigates to tracking price inventory'
		},
		[SAVED]: {
			element: 'Saved link',
			result: 'User navigates to saved inventory'
		},
		[SHOP_OUR_INVENTORY]: {
			element: 'Shop our inventory button',
			result: 'User navigated to all inventory'
		}
	};

	return trackingObjContainer;
};
