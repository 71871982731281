import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, useSitemap, usePrefs } from 'wsm-common-data';

const ModalCard = forwardRef(({ isMobile, welcome, onClickHandler }, ref) => {
	const shoppingActivityRef = useRef(null);
	const labels = useLabels();
	const sitemap = useSitemap();
	const SHOP_OUR_INVENTORY = labels.get('SHOP_OUR_INVENTORY');
	const START_SHOPPING = labels.get('START_SHOPPING');
	const TRACK_VEHICLES_INTERACTED = labels.get(
		'MYCARS_TRACK_VEHICLES_INTERACTED'
	);
	const WELCOME_BACK = labels.get('WELCOME_BACK');
	const MYCARS_JUMP_BACK_IN = labels.get('MYCARS_JUMP_BACK_IN');
	const { shoppingLinkAlias } = usePrefs();

	const cardClasses = [
		'w-100',
		'flex-basis-0',
		'flex-grow-1',
		'border',
		isMobile ? 'mt-5' : 'mt-0'
	];

	const cardHeaderClasses = [
		'mb-4',
		isMobile ? 'mt-7 display-3' : 'mt-8 mx-10 display-4'
	];

	const cardBarClasses = [
		'bg-primary',
		'w-25',
		'border-bottom',
		'border-primary',
		isMobile ? 'border-left border-right px-2 mt-3 mb-3' : 'mt-5 mb-5'
	];

	const cardBodyClasses = [
		'font-weight-normal',
		isMobile ? 'mt-4 mb-3 px-1 mx-3' : 'ddc-font-size-small mb-5 mx-10'
	];

	const cardButtonClasses = [
		'rounded-0',
		'font-weight-bold',
		'btn',
		'btn-primary',
		isMobile ? 'mt-3 mb-7' : 'mb-8',
		isMobile ? 'ddc-font-size-small' : 'ddc-font-size-xsmall'
	];

	useImperativeHandle(ref, () => ({
		getShoppingActivityRef: () => shoppingActivityRef.current
	}));

	return (
		<div id="modal-card" className={setClassNames(cardClasses)}>
			<div className={isMobile ? 'mx-5' : ''}>
				<div className="text-center">
					<h1 className={setClassNames(cardHeaderClasses)}>
						{welcome ? WELCOME_BACK : START_SHOPPING}!
					</h1>
					<hr className={setClassNames(cardBarClasses)} />
					<p className={setClassNames(cardBodyClasses)}>
						{welcome
							? MYCARS_JUMP_BACK_IN
							: TRACK_VEHICLES_INTERACTED}
					</p>
					<a
						ref={shoppingActivityRef}
						id="modal-card-button"
						type="button"
						className={setClassNames(cardButtonClasses)}
						onClick={() => onClickHandler(SHOP_OUR_INVENTORY)}
						href={sitemap.get(shoppingLinkAlias)}
						aria-label={SHOP_OUR_INVENTORY}
						tabIndex={0}
					>
						{SHOP_OUR_INVENTORY}
					</a>
				</div>
			</div>
		</div>
	);
});

ModalCard.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	welcome: PropTypes.bool.isRequired,
	onClickHandler: PropTypes.func.isRequired
};

export default ModalCard;
