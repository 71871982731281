export const resetState = (state) => {
	const { vehicles } = state;
	const resetVehicles = vehicles?.map((vehicle) => {
		return { ...vehicle, newSave: false, newTrackedPrice: false };
	});
	return {
		...state,
		vehicles: resetVehicles,
		newSaveCount: 0,
		newTrackedPriceCount: 0
	};
};
