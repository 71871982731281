import { useEffect } from 'react';
import { hysteriFetch } from 'hysterics';
import {
	UNSUBSCRIBE_ALL,
	VALIDATE,
	UNSUBSCRIBE_SINGLE
} from '../global-constants';

const EASDataService = hysteriFetch('easData', {
	timeout: {
		timeout: 5000 // Decrease at release
	}
});

export const useFetchEAS = ({
	eventType,
	userId,
	priceAlertId,
	isEnableMyCars
}) => {
	const fetchEASData = async (endpoint, requestBody) => {
		const requestUrl = `/api/widget/ws-mycars-nav/${endpoint}`;
		const requestBodyStringified = JSON.stringify(requestBody);

		let response;

		try {
			response = await EASDataService(requestUrl, {
				method: 'POST',
				headers: {
					'content-type': 'application/json;charset=UTF-8'
				},
				body: requestBodyStringified
			});

			window.DDC.pubsub.publish('mycars-nav/fetchEas', response, null);
		} catch (error) {
			window.DDC.pubsub.publish('mycars-nav/fetchEas', null, error);
		}
	};

	useEffect(() => {
		async function fetchData() {
			if (!userId || !eventType) {
				const error = new Error('Invalid arguments');
				window.DDC.pubsub.publish('mycars-nav/fetchEas', null, error);
				return;
			}

			switch (eventType) {
				case VALIDATE: {
					await fetchEASData('validate', {
						userId
					});
					break;
				}
				case UNSUBSCRIBE_SINGLE:
					if (!priceAlertId) {
						const error = new Error('Invalid arguments');
						window.DDC.pubsub.publish(
							'mycars-nav/fetchEas',
							null,
							error
						);
					}
					await fetchEASData('unsubscribe-single', {
						userId,
						priceAlertId
					});
					break;
				case UNSUBSCRIBE_ALL:
					await fetchEASData('unsubscribe-all', {
						userId
					});
					break;
				default: {
					const error = new Error('Invalid eventType');
					window.DDC.pubsub.publish(
						'mycars-nav/fetchEas',
						null,
						error
					);
				}
			}
		}
		if (isEnableMyCars) {
			fetchData();
		}
	}, [eventType, userId, priceAlertId, isEnableMyCars]);
};
