import { useEffect, useState } from 'react';
import { hysteriFetch } from 'hysterics';

import { MYCARS_NAV_PREFIX } from '../server/namespaces';
import { EVENT_TYPE } from '../global-constants';

const ENDPOINT_FETCH_ERROR = `${MYCARS_NAV_PREFIX}/CLIENT/PRICE_ALERT_FETCH_ERROR`;

const service = hysteriFetch('EAS.SERVICE', {
	timeout: {
		timeout: 5000
	}
});

export const useFetchData = ({ userId, eventType, isEnableMyCars }) => {
	const [response, setResponse] = useState([]);

	const priceAlerts = async () => {
		const reqUrl = `/api/widget/ws-mycars-nav/price-alert/${userId}`;

		try {
			const responseData = await service(reqUrl);
			setResponse(responseData.alerts);
		} catch (err) {
			throw new Error(`${ENDPOINT_FETCH_ERROR}: ${err.message}`);
		}
	};

	useEffect(() => {
		if (
			userId &&
			(eventType === EVENT_TYPE.VALIDATE ||
				eventType === EVENT_TYPE.UNSUBSCRIBE_SINGLE ||
				!eventType) &&
			isEnableMyCars
		) {
			priceAlerts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	return { response };
};
