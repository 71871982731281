import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import { getActivityData } from '../utils/getActivityData';
import { resetState } from '../utils/resetState';
import { getTrackingObjContainer } from '../utils/getTrackingObjContainer';
import ModalCard from './ModalCard';
import { setMyCarsData as setMyCarsDataAction } from '../actions/setMyCarsData.js';
import { MY_CARS_KEY } from '../global-constants';
import ModalNav from './ModalNav';

function MyCarsModal({ isOpen, onModalClose }) {
	const dispatch = useDispatch();
	const myCarsData = useSelector((state) => state.myCarsData);
	const { deviceType, windowId, widgetName } = useRequestData();
	const isDesktop = deviceType === 'DESKTOP';
	const { totalActivityCount } = getActivityData(myCarsData.vehicles);
	const welcome = totalActivityCount > 0;
	const labels = useLabels();
	const RECENTLY_VIEWED = labels.get('RECENTLY_VIEWED');
	const TRACKING_PRICE = labels.get('TRACKING_PRICE');
	const SAVED = labels.get('SAVED');
	const SHOPPING_ACTIVITY = labels.get('SHOPPING_ACTIVITY');
	const SHOP_OUR_INVENTORY = labels.get('SHOP_OUR_INVENTORY');
	const trackingObjLabels = {
		RECENTLY_VIEWED,
		TRACKING_PRICE,
		SAVED,
		SHOP_OUR_INVENTORY
	};

	const trackingObjContainer = getTrackingObjContainer(trackingObjLabels);

	const modalDialogueClasses = [isDesktop ? 'mt-25 modal-lg' : 'pl-5'];

	const modalNavRef = useRef(null);
	const closeButtonRef = useRef(null);
	const modalCardRef = useRef(null);

	const headerClasses = [
		'border-bottom-0',
		'd-flex',
		'pb-0',
		!isDesktop
			? 'mt-1 pt-4 justify-content-between'
			: 'pt-3 pr-3 justify-content-end'
	];

	const modalContentClasses = [
		'd-flex',
		'flex-wrap',
		!isDesktop ? 'flex-column px-4' : 'px-6'
	];

	const handleKeyDown = (e) => {
		if (e.key !== 'Tab' || !modalNavRef.current) return;

		const focusableElements = [
			...modalNavRef.current.getLinkNodes(),
			modalCardRef.current?.getShoppingActivityRef(),
			closeButtonRef.current
		].filter(Boolean);
		const currentFocusIndex = focusableElements.indexOf(
			document.activeElement
		);
		let nextFocusIndex = currentFocusIndex + (e.shiftKey ? -1 : 1);
		if (nextFocusIndex >= focusableElements.length) {
			nextFocusIndex = 0;
		} else if (nextFocusIndex < 0) {
			nextFocusIndex = focusableElements.length - 1;
		}
		e.preventDefault();
		focusableElements[nextFocusIndex]?.focus();
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const onClickHandler = (title) => {
		const trackingObj = trackingObjContainer[title];
		trackEvent(widgetName, windowId, trackingObj);
		dispatch(setMyCarsDataAction(resetState(myCarsData, MY_CARS_KEY)));
	};

	const onOpen = () => {
		const primaryLinkNodes = modalNavRef.current.getLinkNodes();
		if (primaryLinkNodes?.length > 0) {
			primaryLinkNodes[0].focus();
		}
	};

	return (
		<Modal
			onHide={onModalClose}
			show={isOpen}
			onEntered={onOpen}
			className={!isDesktop ? 'dock-right' : ''}
			dialogClassName={setClassNames(modalDialogueClasses)}
			aria-labelledby="mycars-modal-title"
			aria-describedby="mycars-modal-title"
		>
			<div id="modal-content" className={setClassNames(headerClasses)}>
				{!isDesktop ? (
					<h3
						aria-level="2"
						id="mycars-modal-title"
						className="ddc-font-size-large pt-3 mt-2 pl-4 font-weight-normal modal-title"
					>
						{SHOPPING_ACTIVITY}
					</h3>
				) : null}
				<button
					ref={closeButtonRef}
					type="button"
					aria-label={labels.get('CLOSE_MODAL')}
					className="btn-unstyled text-link-muted py-3 px-4"
					onClick={() => onModalClose()}
				>
					<i
						className="ddc-icon ddc-icon-remove2 ddc-icon-size-large"
						aria-hidden="true"
					/>
				</button>
			</div>
			<div className={isDesktop ? 'px-6 pb-10 pt-1' : ''}>
				<div className={setClassNames(modalContentClasses)}>
					<ModalNav
						ref={modalNavRef}
						isMobile={!isDesktop}
						myCarsData={myCarsData}
						onClickHandler={onClickHandler}
					/>
					<ModalCard
						ref={modalCardRef}
						isMobile={!isDesktop}
						welcome={welcome}
						onClickHandler={onClickHandler}
					/>
				</div>
			</div>
		</Modal>
	);
}

MyCarsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onModalClose: PropTypes.func.isRequired
};

export default MyCarsModal;
