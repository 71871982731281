import { stateCountValidator } from './stateCountValidator';

export const unsubscribeSingle = (state, vehicleId) => {
	const { vehicles } = state;

	const updatedVehicles = vehicles.map((vehicle) => {
		const updatedVehicle = { ...vehicle };
		if (updatedVehicle.uuid === vehicleId) {
			if (updatedVehicle.saved) {
				// this will delete tracked info on unsubscribeSingle page when there is saved attribute present
				const {
					tracked: _tracked,
					newTrackedPrice: _newTrackedPrice,
					priceAlertId: _priceAlertId,
					...rest
				} = updatedVehicle;
				return rest;
			} else {
				// this will delete tracked and lead info on unsubscribeSingle if no save attribute is present
				const {
					tracked: _tracked,
					newTrackedPrice: _newTrackedPrice,
					priceAlertId: _priceAlertId,
					stockNumber: _stockNumber,
					vehicleTitle: _vehicleTitle,
					...rest
				} = updatedVehicle;
				return rest;
			}
		}

		return updatedVehicle;
	});

	const filteredVehicles = updatedVehicles.filter(
		(vehicle) => vehicle.tracked || vehicle.saved || vehicle.viewed
	);

	return stateCountValidator({
		...state,
		vehicles: filteredVehicles
	});
};
