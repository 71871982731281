const updateVehiclesArray = (newAlerts, localVehicles) => {
	const updatedVehicles = [...localVehicles];
	newAlerts.forEach((alert) => {
		const existingVehicleIndex = updatedVehicles.findIndex(
			(vehicle) => vehicle.uuid === alert.vehicleId
		);
		if (existingVehicleIndex === -1) {
			updatedVehicles.push({
				uuid: alert.vehicleId,
				tracked: alert.lastModified,
				newTrackedPrice: false,
				priceAlertId: alert.priceAlertId,
				stockNumber: alert.stockNumber,
				vehicleTitle: alert.vehicleTitle
			});
		} else if (!updatedVehicles[existingVehicleIndex].tracked) {
			updatedVehicles[existingVehicleIndex].tracked = alert.lastModified;
			updatedVehicles[existingVehicleIndex].newTrackedPrice = false;
		}
	});
	return updatedVehicles;
};

export const updateAlerts = (state, alerts) => {
	const { vehicles } = state;
	const updatedVehicles = updateVehiclesArray(alerts, vehicles);

	return {
		...state,
		vehicles: updatedVehicles
	};
};
