export const stateCountValidator = (state) => {
	const { vehicles } = state;
	const newSaveCount = vehicles?.filter(
		(vehicle) => vehicle.newSave === true || vehicle.newSave === 'true'
	).length;
	const newTrackedPriceCount = vehicles?.filter(
		(vehicle) =>
			vehicle.newTrackedPrice === true ||
			vehicle.newTrackedPrice === 'true'
	).length;
	return {
		...state,
		newSaveCount,
		newTrackedPriceCount
	};
};
