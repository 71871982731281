import { SET_MY_CARS_DATA } from '../actions/types';

export default function myCarsData(state = {}, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_MY_CARS_DATA:
				return action.payload;
			// no default
		}
	}

	return state;
}
