import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const Badge = ({ count }) => {
	const labels = useLabels();

	return (
		<span className="highlight-badge badge-small default ml-4">
			{`${count} ${labels.get('NEW')}`}
		</span>
	);
};

Badge.propTypes = {
	count: PropTypes.number.isRequired
};

export default Badge;
