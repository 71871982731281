export const updateLocalStorage = (newState, localStorageKey, topic) => {
	try {
		// Save to local storage
		window.localStorage.setItem(localStorageKey, JSON.stringify(newState));

		window.DDC.pubsub.publish(topic);
	} catch (error) {
		// TODO: add a NR attribute
	}
};
