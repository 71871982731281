import React, { useEffect, useRef, useImperativeHandle, forwardRef, createRef } from 'react';
import PropTypes from 'prop-types';
import { useLabels, useSitemap } from 'wsm-common-data';
import { getShoppingActivityData } from '../utils/getShoppingActivityData';
import { MyCarsDataPropTypes } from '../propTypes';
import Link from './Link';

const ModalNav = forwardRef(({ isMobile, myCarsData, onClickHandler }, ref) => {
	const { vehicles, newSaveCount, newTrackedPriceCount } = myCarsData;
	const labels = useLabels();
	const SHOPPING_ACTIVITY = labels.get('SHOPPING_ACTIVITY');
	const RECENTLY_VIEWED = labels.get('RECENTLY_VIEWED');
	const TRACKING_PRICE = labels.get('TRACKING_PRICE');
	const SAVED = labels.get('SAVED');

	const linkRefs = useRef([...Array(3)].map(() => createRef()));
	useImperativeHandle(ref, () => ({
		getLinkNodes: () => linkRefs.current.map(linkRef => linkRef.current.getLinkNode())
	}));

	const sitemap = useSitemap();
	const INVENTORY_LISTING_SHOPPING_ACTIVITY = sitemap.get(
		'INVENTORY_LISTING_SHOPPING_ACTIVITY'
	);

	const shoppingActivityLabels = {
		RECENTLY_VIEWED,
		TRACKING_PRICE,
		SAVED
	};

	const shoppingActivityLinks = getShoppingActivityData(
		vehicles,
		shoppingActivityLabels
	);

	useEffect(() => {
        linkRefs.current = linkRefs.current.slice(0, shoppingActivityLinks.length);
    }, [shoppingActivityLinks.length]);

	return (
		<div id="modal-nav" className="mb-1 flex-basis-0 flex-grow-1">
			<nav>
				{!isMobile ? (
					<h2
						id="mycars-modal-title"
						aria-level="2"
						className="mt-0 h5 text-body"
					>
						{SHOPPING_ACTIVITY}
					</h2>
				) : null}
				<ul className="list-group mb-0">
					{shoppingActivityLinks.map(
						({ title, hrefParam, count }, index) => (
							<li key={title}>
								<Link
									ref={linkRefs.current[index]}
									title={title}
									href={
										INVENTORY_LISTING_SHOPPING_ACTIVITY +
										hrefParam
									}
									count={count}
									newSaveCount={newSaveCount}
									newTrackedPriceCount={newTrackedPriceCount}
									isMobile={isMobile}
									onClickHandler={onClickHandler}
								/>
							</li>
						)
					)}
				</ul>
			</nav>
		</div>
	);
});

ModalNav.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	onClickHandler: PropTypes.func.isRequired,
	myCarsData: MyCarsDataPropTypes
};

export default ModalNav;
