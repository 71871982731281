// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const MY_CARS_KEY = 'mycars-user';
export const TOPIC_KEY = 'mycars-user-update';

export const UNSUBSCRIBE_ALL = 'unsubscribeAll';
export const VALIDATE = 'validate';
export const UNSUBSCRIBE_SINGLE = 'unsubscribeSingle';

export const NR_PREFIX = 'MYCARS_NAV';
export const ENDPOINT_FETCH_ERROR = `${NR_PREFIX}/CLIENT/VEHICLES_ENDPOINT_FETCH_ERROR`;

export const EVENT_TYPE = {
	VALIDATE: 'validate',
	UNSUBSCRIBE_SINGLE: 'unsubscribeSingle',
	UNSUBSCRIBE_ALL: 'unsubscribeAll'
};
