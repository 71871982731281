import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';
import Badge from './Badge';

const Link = forwardRef(({
	title,
	href,
	count,
	newSaveCount,
	newTrackedPriceCount,
	isMobile,
	onClickHandler
}, ref) => {
	const linkRef = useRef(null);

	const labels = useLabels();
	const shoppingActivityLinkClasses = [
		'modal-nav-link',
		'list-group-item',
		'px-0 pl-4 mr-5',
		isMobile ? 'text-primary-500' : 'modal-nav-link--desktop'
	];
	const isNewTrackedPriceCount =
		title === labels.get('TRACKING_PRICE') && newTrackedPriceCount > 0;
	const isNewSaveCount = title === labels.get('SAVED') && newSaveCount > 0;

	useImperativeHandle(ref, () => ({
		getLinkNode: () => linkRef.current
	}));

	const generateAriaLabel = () => {
		const baseLabel = labels
			.get('MYCARS_OPEN_LINK')
			.replace('{title}', title)
			.replace('{count}', count);
		let additionalLabel = '';

		if (isNewTrackedPriceCount) {
			additionalLabel = `${newTrackedPriceCount} ${labels.get('NEW')}`;
		} else if (isNewSaveCount) {
			additionalLabel = `${newSaveCount} ${labels.get('NEW')}`;
		}

		return `${baseLabel} ${additionalLabel}`.trim();
	};

	return (
		<a
			ref={linkRef}
			href={href}
			className={setClassNames(shoppingActivityLinkClasses)}
			onClick={() => onClickHandler(title)}
			aria-label={generateAriaLabel()}
		>
			{title}
			<small className="ml-3 text-muted ddc-font-size-xsmall">
				{count}
			</small>
			{isNewTrackedPriceCount && <Badge count={newTrackedPriceCount} />}
			{isNewSaveCount && <Badge count={newSaveCount} />}
		</a>
	);
});

Link.propTypes = {
	title: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	newSaveCount: PropTypes.number.isRequired,
	newTrackedPriceCount: PropTypes.number.isRequired,
	onClickHandler: PropTypes.func.isRequired,
	isMobile: PropTypes.bool.isRequired
};

export default Link;
