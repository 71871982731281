import { getActivityData } from './getActivityData';

export const getShoppingActivityData = (vehicles, labels) => {
	const { recentlyViewedCount, trackingPriceCount, savedCount } =
		getActivityData(vehicles);
	const {
		RECENTLY_VIEWED: recentlyViewed,
		TRACKING_PRICE: trackingPrice,
		SAVED: saved
	} = labels;

	return [
		{
			title: recentlyViewed,
			count: recentlyViewedCount,
			hrefParam: '?select=viewed'
		},
		{
			title: trackingPrice,
			count: trackingPriceCount,
			hrefParam: '?select=tracked'
		},
		{
			title: saved,
			count: savedCount,
			hrefParam: '?select=saved'
		}
	];
};
