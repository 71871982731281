export const getActivityData = (vehicles) => {
	const recentlyViewedCount =
		vehicles?.filter((vehicle) => vehicle.viewed !== '' && vehicle.viewed)
			?.length ?? 0;

	const trackingPriceCount =
		vehicles?.filter((vehicle) => vehicle.tracked !== '' && vehicle.tracked)
			?.length ?? 0;

	const savedCount =
		vehicles?.filter((vehicle) => vehicle.saved !== '' && vehicle.saved)
			?.length ?? 0;

	return {
		recentlyViewedCount,
		trackingPriceCount,
		savedCount,
		totalActivityCount:
			recentlyViewedCount + trackingPriceCount + savedCount
	};
};
