import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMyCarsData as setMyCarsDataAction } from '../actions/setMyCarsData.js';
import { TOPIC_KEY } from '../global-constants';
import { stateCountValidator, isStateEqual } from '../utils';

/* Check if localStore has all keys in the template, if not, add one with template data. */
const templateCheck = (state, template) => {
	// Find missing keys
	const stateKeys = Object.keys(state);
	const templateKeys = Object.keys(template);
	const diff = templateKeys.filter((x) => !stateKeys.includes(x));
	const missingKeys = {};

	diff.forEach((key) => {
		missingKeys[key] = template[key];
	});

	return { ...state, ...missingKeys };
};

const isValidState = (state) => {
	const { vehicles } = state;
	const newSaveCount = vehicles?.filter(
		(vehicle) => vehicle.newSave === true || vehicle.newSave === 'true'
	).length;
	const newTrackedPriceCount = vehicles?.filter(
		(vehicle) =>
			vehicle.newTrackedPrice === true ||
			vehicle.newTrackedPrice === 'true'
	).length;
	if (
		newSaveCount !== parseInt(state.newSaveCount, 10) ||
		newTrackedPriceCount !== parseInt(state.newTrackedPriceCount, 10)
	) {
		return false;
	} else {
		return true;
	}
};

export const useLocalStorage = (
	localStorageKey,
	initialLocalStorageValue,
	isEnableMyCars
) => {
	const prevLocalStorage = useRef();
	const dispatch = useDispatch();
	const myCarsData = useSelector((state) => state.myCarsData);
	const setInitialState = () => {
		try {
			// Get from local storage by key
			let item = JSON.parse(window.localStorage.getItem(localStorageKey));
			// Parse stored json or if none return initialLocalStorageValue

			if (
				item &&
				typeof item === 'object' &&
				Object.keys(item).length > 0
			) {
				item = templateCheck(item, initialLocalStorageValue);
				if (isValidState(item)) {
					window.localStorage.setItem(
						localStorageKey,
						JSON.stringify(item)
					);
					dispatch(setMyCarsDataAction(item));
					prevLocalStorage.current = item;
				} else {
					const validCountState = stateCountValidator(item);
					window.localStorage.setItem(
						localStorageKey,
						JSON.stringify(validCountState)
					);
					dispatch(setMyCarsDataAction(validCountState));
					prevLocalStorage.current = validCountState;
				}
			} else {
				window.localStorage.setItem(
					localStorageKey,
					JSON.stringify(initialLocalStorageValue)
				);
				dispatch(setMyCarsDataAction(initialLocalStorageValue));
				prevLocalStorage.current = initialLocalStorageValue;
			}
		} catch (error) {
			// If the item hasn't the proper format to be converted in
			// an object, we need to catch the error here and set
			// the initial value instead
			dispatch(setMyCarsDataAction(initialLocalStorageValue));
			prevLocalStorage.current = initialLocalStorageValue;
		}
	};

	const setStateAndLocalStorage = (newStateValue) => {
		try {
			if (newStateValue) {
				const newState = {
					...myCarsData,
					...newStateValue
				};
				const validCountState = stateCountValidator(newState);
				// Save to local storage
				window.localStorage.setItem(
					localStorageKey,
					JSON.stringify(validCountState)
				);
				// Save state
				dispatch(setMyCarsDataAction(validCountState));
				// Publish to DDC.pubsub to notify other tabs
				window.DDC.pubsub.publish(TOPIC_KEY);
				prevLocalStorage.current = validCountState;
			}
		} catch (error) {
			// TODO: add a NR attribute
		}
	};

	function storageEventHandler() {
		const item = JSON.parse(localStorage.getItem(localStorageKey));
		if (
			item &&
			typeof item === 'object' &&
			Object.keys(item).length > 0 &&
			!isStateEqual(prevLocalStorage.current, item)
		) {
			if (isValidState(item)) {
				dispatch(setMyCarsDataAction(item));
				prevLocalStorage.current = item;
			} else {
				setStateAndLocalStorage(item);
			}
		}
	}

	// We subscribe to  DDC.pubsub to listen for changes in localStorage
	useEffect(() => {
		let subscriber;
		if (isEnableMyCars) {
			setInitialState();
			subscriber = window.DDC.pubsub.subscribe(
				TOPIC_KEY,
				storageEventHandler
			);
		}
		return () => {
			if (subscriber && typeof subscriber.unsubscribe === 'function') {
				subscriber.unsubscribe();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
