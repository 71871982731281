export const unsubscribeAll = (state) => {
	const { vehicles } = state;
	const updatedVehicles = vehicles.map((vehicle) => {
		const updatedVehicle = { ...vehicle };
		delete updatedVehicle.newTrackedPrice;
		delete updatedVehicle.tracked;
		delete updatedVehicle.priceAlertId;

		// deletes stockNumber and vehicleTitle on unsubscribeAll if saved attribute is not present
		if (updatedVehicle.saved || updatedVehicle.viewed) {
			if (!updatedVehicle.saved) {
				delete updatedVehicle.stockNumber;
				delete updatedVehicle.vehicleTitle;
			}
			return updatedVehicle;
		}

		return null;
	});

	return {
		...state,
		newTrackedPriceCount: 0,
		vehicles: updatedVehicles.filter((vehicle) => vehicle !== null)
	};
};
